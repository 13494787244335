@charset "utf-8";
@import url('//fonts.googleapis.com/css?family=Material+Icons');
@import url('//fonts.googleapis.com/css2?family=Goldman:wght@400;700&family=Josefin+Sans:wght@400;700&family=Space+Grotesk:wght@400;700&display=swap');

/*@import url('//s3.amazonaws.com/icomoon.io/114779/Socicon/style.css?u8vidh');
@import url('//d1azc1qln24ryf.cloudfront.net/114779/Socicon/style-cf.css?u8vidh');*/


/********** CSS Document **********/
/*--- Variables ---*/
:root{
    --dark-gray: #222222;
    --light-white: #F1FAEE;
    --celadon-blue: #457B9D;
    --highlight-color: #E63946;
    --twitter-color: #00ACEE;
    --linkedin-color: #0E76A8;
    --section-color-1: #A8DADC;
    --section-color-2: #1D3557;
}


/*--- General ---*/
html,
#root{
    height: 100%;
}
html.no_scroll {
    overflow: hidden;
}
body {
    position: relative;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: var(--dark-gray);
    height: 100%;
    margin: 0px;
    padding: 0px;
}
.main-container{
    position: relative;
    height: 100%;
}
h1,
h2 {
    font-size: 30px;
    margin: 0px;
}
h3 {
    font-size: 20px;
    margin: 0px;
}
h4{
    font-size: 20px;
    margin: 0px;
}
hr{
    margin: 30px 0px;
    border-width: 1px 0px 0px;
    border-style: solid;
    border-color: var(--dark-gray);
}
a{
    color: var(--light-white);
    text-decoration: none;
    word-wrap: break-word;
}
a:focus,
a:visited{
    color: var(--light-white);
}
a.links{
    position: relative;
    color: var(--section-color-1);
    text-decoration: underline;
}
/*a.links:after{
    content: "";
    background: var(--section-color-1);
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1px;
}*/
a.underline{
    position: relative;
    text-decoration: none !important;
}
a.underline:after{
    content: "";
    background: var(--light-white);
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 2px;
    opacity: 0;
    -webkit-transform: translateY(5px);
	-moz-transform: translateY(5px);
	-ms-transform: translateY(5px);
	-o-transform: translateY(5px);
    transform: translateY(5px);
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}
a.links.underline:after{
    background: var(--section-color-1);
}
a.underline:hover:after{
    opacity: 1;
    -webkit-transform: translateY(1px);
	-moz-transform: translateY(1px);
	-ms-transform: translateY(1px);
	-o-transform: translateY(1px);
    transform: translateY(1px);
}
.btn{
    display: inline-block;
    padding: 5px 30px;
    border: 1px solid var(--light-white);
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}
.btn.dark-gray{
    color: var(--dark-gray);
    border-color: var(--dark-gray);
}
.btn:hover{
    background: var(--light-white);
    color: var(--dark-gray);
}
.btn.dark-gray:hover{
    background: var(--dark-gray);
    color: var(--light-white);
}
ul {
    margin: 0px;
    padding: 0px;
}
.section{
    padding-top: 50px;
    padding-bottom: 50px;
}
.section.section-bg-1{
    background: var(--light-white);
}
.section.section-bg-2{
    background: var(--section-color-1);
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.desktop,
.desktop_inline_block,
.desktop_break {
    display: none;
}
.mobile {
    display: block;
}
.mobile_inline_block {
    display: inline-block;
}
.mobile_break {
    display: inline;
}
.clear {
    clear: both;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: '';
    clear: both;
}
.box_sizing {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}


/*--- Banner ---*/
.banner{
    position: relative;
    background: url(../media/banner.jpg) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}
.banner > div{
    background: var(--light-white);
    padding: 15px 95px;
    text-align: center;
}
.banner h1{
    position: relative;
    font-family: 'Josefin Sans', sans-serif;
    margin-top: 5px;
}
.banner h1:before,
.banner h1:after{
    content: " ";
    background: var(--dark-gray);
    position: absolute;
    top: 50%;
    width: 60px;
    height: 2px;
    margin: 0px 10px;
}
.banner h1:before{
    right: 100%;
}
.banner h1:after{
    left: 100%;
}
.banner .scroll-down{
    display: block;
    background: var(--dark-gray);
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 60px;
    /*color: var(--light-white);*/
    text-align: center;
    z-index: 6;
}
.banner .scroll-down span{
    font-size: 30px;
    /*padding-bottom: 15px;*/
    -webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-name: wiggle;
    animation-duration: 1.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
}


/*--- Menu ---*/
nav{
    background: var(--dark-gray);
    position: absolute;
    top: 100%;
    right: 0px;
    width: 100%;
    z-index: 999;
}
nav.sticky{
    position: fixed;
    top: 0px;
}
nav > .menu-mobile{
    position: relative;
    width: 100%;
    height: 45px;
}
nav > .menu-mobile button{
    display: block;
    background: transparent;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 22px;
    height: 15px;
    margin: 15px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
}
nav > .menu-mobile button span{
    display: block;
    background: var(--light-white);
    position: absolute;
    top: 0px;
    right: 0px;
    width: 22px;
    height: 3px;
    -webkit-transition:width 300ms ease;
    -moz-transition:width 300ms ease;
    -ms-transition:width 300ms ease;
    -o-transition:width 300ms ease;
    transition:width 300ms ease;
}
nav > .menu-mobile button span:before,
nav > .menu-mobile button span:after{
    content: "";
    background: var(--light-white);
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transition:width 300ms ease;
    -moz-transition:width 300ms ease;
    -ms-transition:width 300ms ease;
    -o-transition:width 300ms ease;
    transition:width 300ms ease;
}
nav > .menu-mobile button span:before{
    top: 6px;
}
nav > .menu-mobile button span:after{
    top: 12px;
}
nav > .menu-mobile.active span:before{
    width: 70%;
}
nav > .menu-mobile.active span:after{
    width: 30%;
}
nav ul{
    display: none;
    background: var(--dark-gray);
    list-style: none;
    position: absolute;
    width: 100%;
    z-index: 99;
}
nav > .menu-mobile.active ~ ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
nav ul li a{
    width: 100%;
    padding: 10px 0px;
    /*color: var(--light-white);*/
    display: flex;
    align-items: center;
    justify-content: center;
}
nav ul li a:hover,
nav ul li a.active{
    background: var(--highlight-color);
}


/*--- History ---*/
.history-list > div:nth-child(2){
    margin-top: 10px;
}


/*--- Ability ---*/
.ability-item{
    position: relative;
}
.ability-list > div > div > div:nth-child(n + 2) .ability-item{
    margin-top: 10px;
}
.ability-item > img{
    margin-right: 15px;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}
.ability-item:hover > img{
    margin-left: 10px;
}


/*--- Portfolio ---*/
.portfolio-item{
    position: relative;
}
.portfolio-item > h3{
    font-size: 20px;
}
.portfolio-item > h3 > a{
    position: relative;
    color: var(--celadon-blue);
}
.portfolio-item > h3 > a:after{
    background: var(--celadon-blue);
}
.portfolio-more-dots{
    display: none;
    position: relative;
    cursor: pointer;
}
.portfolio-more-dots.active{
    display: inline-block;
}
.portfolio-more-dots.active > span{
    /*background: var(--dark-gray);
    width: 5px;
    height: 5px;
    border-radius: 100%;*/
    -webkit-animation: moving 1.4s infinite ease-in-out both;
    animation: moving 1.4s infinite ease-in-out both;
}
.portfolio-more-dots.active > span:nth-child(1){
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.portfolio-more-dots.active > span:nth-child(2){
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.portfolio-more-text{
    display: none;
    position: relative;
}
.portfolio-more-text.active{
    display: inline-block;
}
.portfolio-more-text.active > span{
    display: inline-block;
    /*-webkit-animation: moving2 1.6s ease-in both;
    animation: moving2 1.6s ease-in both;*/
    -webkit-animation: moving2 0.1s ease-in both;
    animation: moving2 0.1s ease-in both;
}
/*.portfolio-more-text.active > span:nth-child(1){
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.portfolio-more-text.active > span:nth-child(2){
    -webkit-animation-delay: -0.55s;
    animation-delay: -0.55s;
}
.portfolio-more-text.active > span:nth-child(3){
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.portfolio-more-text.active > span:nth-child(4){
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s;
}
.portfolio-more-text.active > span:nth-child(5){
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.portfolio-more-text.active > span:nth-child(6){
    -webkit-animation-delay: -0.35s;
    animation-delay: -0.35s;
}
.portfolio-more-text.active > span:nth-child(7){
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.portfolio-more-text.active > span:nth-child(8){
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
}
.portfolio-more-text.active > span:nth-child(9){
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.portfolio-more-text.active > span:nth-child(10){
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
}
.portfolio-more-text.active > span:nth-child(11){
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}
.portfolio-more-text.active > span:nth-child(12){
    -webkit-animation-delay: -0.05s;
    animation-delay: -0.05s;
}*/
.portfolio-more-text.active > span:nth-child(2){
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.portfolio-more-text.active > span:nth-child(3){
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.portfolio-more-text.active > span:nth-child(4){
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}
.portfolio-more-text.active > span:nth-child(5){
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.portfolio-more-text.active > span:nth-child(6){
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.portfolio-more-text.active > span:nth-child(7){
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.portfolio-more-text.active > span:nth-child(8){
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}
.portfolio-more-text.active > span:nth-child(9){
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}
.portfolio-more-text.active > span:nth-child(10){
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}
.portfolio-more-text.active > span:nth-child(11){
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.portfolio-more-text.active > span:nth-child(12){
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}
.portfolio-more-text.active > span:nth-child(13){
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}


/*--- Footer ---*/
footer{
    background: var(--section-color-2);
    color: var(--light-white);
}
footer hr{
    border-color: var(--light-white);
}
footer h4{
    /*font-family: 'Goldman', cursive;*/
    font-family: 'Josefin Sans', sans-serif;
    font-weight: normal;
    font-size: 23px;
}
footer .btn-download{
    margin-top: 10px;
}
footer .socicon{
    font-size: 15px;
    margin-right: 15px;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}
footer .socicon[title="Twitter"]:hover{
    color: var(--twitter-color);
}
footer .socicon[title="LinkedIn"]:hover{
    color: var(--linkedin-color);
}


/*--- Animation ---*/
@keyframes wiggle{
	0%{
	    padding-bottom: 13px;
	}
	50%{
		padding-bottom: 20px;
	}
	100%{
	    padding-bottom: 13px;
	}
}
@keyframes moving{
    0%, 80%, 100%{
        opacity: 0;
    }
    40%{
        opacity: 1;
    }
}
@keyframes moving2{
    0%{
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    /*50%{
        opacity: 0.7;
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }*/
    100%{
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes reappear{
    100%{
        opacity: 1;
    }
}