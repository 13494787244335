/* requires: styles.css */

@charset "utf-8";

/********** CSS Document **********/

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
    /*--- General ---*/
    html.no_scroll {
        overflow: auto;
    }
    h1,
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 25px;
    }
    hr{
        margin: 40px 0px;
    }
    .section{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .desktop,
    .desktop_break {
        display: block;
    }
    .desktop_inline_block {
        display: inline-block;
    }
    .mobile,
    .mobile_inline_block {
        display: none;
    }

    
    /*--- Banner ---*/
    .banner > div{
        padding: 30px 130px;
    }
    .banner h1:before,
    .banner h1:after{
        width: 80px;
    }
    .banner .scroll-down{
        right: 5%;
        width: 110px;
        height: 80px;
    }
    .banner .scroll-down span{
        font-size: 40px;
        /*padding-bottom: 20px;*/
    }


    /*--- Menu ---*/
    nav{
        right: 5%;
        width: 110px;
    }
    nav.sticky{
        top: 60px;
    }
    nav > .menu-mobile{
        display: none;
    }
    nav ul{
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        position: relative;
    }
    nav ul li a{
        height: 80px;
        padding: 0px;
    }


    /*--- History ---*/
    .history-list > div:nth-child(2){
        margin-top: 0px;
    }


    /*--- Ability ---*/
    .ability-list > div > div > div:nth-child(2) .ability-item,
    .ability-list > div > div > div:nth-child(3) .ability-item{
        margin-top: 0px;
    }
    .ability-list > div > div > div:nth-child(n + 4) .ability-item{
        margin-top: 10px;
    }


    /*--- Footer ---*/
    footer.section{
        padding-top: 100px;
        padding-bottom: 150px;
    }


    /*--- Animation ---*/
    @keyframes wiggle{
        0%{
            padding-bottom: 18px;
        }
        50%{
            padding-bottom: 25px;
        }
        100%{
            padding-bottom: 18px;
        }
    }
}

@media (min-width: 1200px) {
    
}